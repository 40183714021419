import React, { useState } from "react";

const WehicleReplacementForm = ({ setShowModal }) => {
  const apiUrl = "https://atmo.veribiscrm.com/api/web/updatedata";
  const AppointedUserId = 2482;
  const ActivityGroup = 99;
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [model, setModel] = useState("");
  const [location, setLocation] = useState("");
  const [kvkk, setKvkk] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const RepresentInformation = location;

    const CustomerMail = mail;
    const Telephone = phone;
    const DepartmantInformation = message;
    const Category = model;
    const ActivityType = 8065;
    let Name = "";
    let Surname = "";

    const nameArr = name.split(" ");

    if (nameArr.length == 2) {
      Name = nameArr[0];
      Surname = nameArr[1];
    } else {
      Surname = nameArr[nameArr.length - 1];
      Name = nameArr.slice(0, nameArr.length - 1).join(" ");
    }

    const data = {
      Name,
      Surname,
      CustomerMail,
      Telephone,
      RepresentInformation,
      DepartmantInformation,
      ActivityType,
      Category,
      ActivityGroup,
      AppointedUserId,
    };
    console.log(data);

    try {
      const response = await fetch(apiUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ApiKey: process.env.REACT_APP_API_KEY,
        },
        method: "POST",
        body: JSON.stringify({
          Table: "Activity",
          Data: data,
        }),
      });

      const result = await response.json();

      console.log(result);

      if (result.Status === 0) {
        setName("");
        setMail("");
        setPhone("");
        setMessage("");
        setModel("");
        setLocation("");
        setKvkk(false);
        alert("İkame Araç Formu gönderildi");
      } else {
        alert("İkame Araç Formu gönderilemedi");
      }
    } catch (err) {
      console.log("Error: ", err);
      alert("Sunucu Hatası");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
      <select
        id="model5"
        name="model5"
        required
        className="p-2 border border-gray-200 rounded-md"
        value={model}
        onChange={(e) => setModel(e.target.value)}
      >
        <option value="">-Model Seçiniz-</option>
        <option value="7345">TIGGO 7 PRO</option>
        <option value="7344">TIGGO 8 PRO</option>
        <option value="7343">OMODA 5</option>
      </select>
      <input
        type="text"
        placeholder="Adınız Soyadınız"
        id="name5"
        name="name5"
        className="p-2 border border-gray-200 rounded-md"
        required
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="email"
        placeholder="E-mail Adresiniz"
        id="email5"
        name="email5"
        className="p-2 border border-gray-200 rounded-md"
        required
        value={mail}
        onChange={(e) => setMail(e.target.value)}
      />
      <input
        type="text"
        placeholder="İl/İlçe"
        id="city"
        name="city"
        className="p-2 border border-gray-200 rounded-md"
        required
        value={location}
        onChange={(e) => setLocation(e.target.value)}
      />
      <input
        type="tel"
        placeholder="Telefon Numaranız"
        id="phone5"
        name="phone5"
        className="p-2 border border-gray-200 rounded-md"
        required
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <textarea
        id="message5"
        placeholder="Mesajınız"
        name="message5"
        className="p-2 border border-gray-200 rounded-md"
        rows="4"
        required
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      ></textarea>
      <div className="flex items-center space-x-2">
        <input type="checkbox" id="kvkk5" name="kvkk5" required />
        <label htmlFor="kvkk5" className="">
          <button
            onClick={() => setShowModal(true)}
            className="font-bold text-red-900"
            type="button"
          >
            KVKK Metni
          </button>
          'ni ve Gizlilik Politikanızı kabul ediyorum.
        </label>
      </div>
      <div className="flex items-center justify-center w-full">
        <button
          type="submit"
          className="w-40 p-1 text-white bg-red-900 rounded-md"
        >
          Gönder
        </button>
      </div>
    </form>
  );
};

export default WehicleReplacementForm;
