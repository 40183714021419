import React, { useState } from "react";

const SuggestionForm = ({ setShowModal }) => {
  const apiUrl = "https://atmo.veribiscrm.com/api/web/updatedata";

  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [kvkk, setKvkk] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const Mail = mail;
    const Telephone = phone;
    const CustomerNote = message;

    let Name = "";
    let SurName = "";

    const nameArr = name.split(" ");

    if (nameArr.length == 2) {
      Name = nameArr[0];
      SurName = nameArr[1];
    } else {
      SurName = nameArr[nameArr.length - 1];
      Name = nameArr.slice(0, nameArr.length - 1).join(" ");
    }
    const data = {
      Name,
      SurName,
      Mail,
      Telephone,
      CustomerNote,
      AppointedUserId: 2482,
      From: 2,
      Stage: 7353,
      cars: 7362,
      Description: ".",
      // DisplayOrder,
      // ParentId,
      // WebType: 1,
    };

    try {
      const response = await fetch(apiUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ApiKey: process.env.REACT_APP_API_KEY,
        },
        method: "POST",
        body: JSON.stringify({
          Table: "Opportunity",
          Data: data,
        }),
      });

      const result = await response.json();

      console.log(result);

      if (result.Status === 0) {
        setName("");
        setMail("");
        setPhone("");
        setMessage("");
        setKvkk(false);
        alert("Öneri Formu gönderildi");
      } else {
        alert("Öneri Formu gönderilemedi");
      }
    } catch (err) {
      console.log("Error: ", err);
      alert("Sunucu Hatası");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
      <input
        type="text"
        placeholder="Adınız Soyadınız"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="p-2 border border-gray-200 rounded-md"
        required
      />
      <input
        type="email"
        placeholder="E-mail Adresiniz"
        value={mail}
        onChange={(e) => setMail(e.target.value)}
        className="p-2 border border-gray-200 rounded-md"
        required
      />
      <input
        type="tel"
        placeholder="Telefon Numaranız"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        className="p-2 border border-gray-200 rounded-md"
        required
      />
      <textarea
        placeholder="Mesajınız"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="p-2 border border-gray-200 rounded-md"
        rows="4"
        required
      ></textarea>

      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          checked={kvkk}
          onChange={() => setKvkk(!kvkk)}
          id="kvkk3"
          name="kvkk3"
          required
        />
        <label htmlFor="kvkk3" className="">
          <button
            onClick={() => setShowModal(true)}
            className="font-bold text-red-900"
            type="button"
          >
            KVKK Metni
          </button>
          'ni ve Gizlilik Politikanızı kabul ediyorum.
        </label>
      </div>
      <div className="flex items-center justify-center w-full">
        <button
          type="submit"
          className="w-40 p-1 text-white bg-red-900 rounded-md"
        >
          Gönder
        </button>
      </div>
    </form>
  );
};

export default SuggestionForm;
