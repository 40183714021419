import React, { useState } from "react";
import atmoImg from "./assets/images/atmo.png";
import cherryImg from "./assets/images/chery.png";
import atomImg from "./assets/images/atom.jpg";
import tiggoImg from "./assets/images/tiggo8pro.jpg";
import ContactForm from "./components/ContactForm";
import SuggestionForm from "./components/SuggestionForm";
import AfterSaleForm from "./components/AfterSaleForm";
import TestDriveForm from "./components/TestDriveForm";
import WehicleReplacementForm from "./components/WehicleReplacementForm";
const App = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [showModal, setShowModal] = useState(false);

  return (
    <main className="w-full h-screen bg-gray-100 ">
      <header className="bg-white w-full h-28 flex justify-between items-center">
        <nav className="flex w-[70vw] mx-auto items-center justify-between">
          <div>
            <img src={atmoImg} alt="" className="h-16 md:h-20" />
          </div>
          <div className="hidden md:block">
            <ul className="flex space-x-4 font-semibold text-lg">
              <li className="hover:text-red-900 duration-300 transition-all">
                <a href="#"> Modeller </a>
              </li>
              <li className="hover:text-red-900 duration-300 transition-all">
                <a href="#">Hizmetlerimiz</a>
              </li>
              <li className="hover:text-red-900 duration-300 transition-all">
                <a href="#">Kampanyalar</a>
              </li>
              <li className="hover:text-red-900 duration-300 transition-all">
                <a href="#">Fiyat Listesi</a>
              </li>
              <li className="hover:text-red-900 duration-300 transition-all">
                <a href="#">İletişim</a>
              </li>
            </ul>
          </div>
          <div>
            <img src={cherryImg} alt="" className="h-20" />
          </div>
        </nav>
      </header>

      <section>
        <div className="w-full relative">
          <img src={atomImg} alt="" className="h-48 object-cover w-full" />
          <div className="absolute bg-black h-full w-full inset-0 opacity-60 z-10"></div>
          <div className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="flex flex-col items-center space-y-4">
              <h1 className="text-5xl text-white">İletişim</h1>
              <a
                href="https://atmochery.com.tr/"
                className="text-2xl p-2 w-[8rem] text-white rounded-md border-white border hover:bg-gray-400/40 hover:text-gray-100 duration-300 transition-all"
              >
                Anasayfa
              </a>
            </div>
          </div>
        </div>

        <div className="mt-4 relative h-40 w-full md:w-[90vw] mx-auto">
          <img
            src={tiggoImg}
            alt=""
            className="w-full md:w-[90vw] mx-auto h-40 object-cover"
          />
          <div className="absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="flex w-full items-center justify-between bg-black/30 md:bg-transparent">
              <div className="flex w-full items-center justify-center">
                <div className="text-2xl text-white flex flex-col items-center space-y-2">
                  <i
                    aria-hidden="true"
                    className="fas fa-envelope-open-text md:text-4xl"
                  ></i>
                  <span className="text-sm md:text-2xl">
                    info@atmochery.com
                  </span>
                </div>
              </div>
              <div className="flex w-full items-center justify-center">
                <div className="text-2xl text-white flex flex-col items-center space-y-2">
                  <i
                    aria-hidden="true"
                    className="fas fa-phone-alt md:text-4xl"
                  ></i>
                  <span className="text-sm md:text-2xl">
                    +90 (216) 232 23 10
                  </span>
                </div>
              </div>
              <div className="flex w-full items-center justify-center">
                <div className="text-2xl text-white flex flex-col items-center space-y-2">
                  <i
                    aria-hidden="true"
                    className="fas fa-map-marker-alt md:text-4xl"
                  ></i>
                  <span className="text-sm md:text-2xl">
                    Ahmet İleri Cad. No: 12, Ümraniye, İstanbul
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full p-2">
          <div className="w-full md:w-[70vw] mx-auto bg-white shadow-md mt-4 rounded-lg border-red-900 border p-4 mb-10">
            <div className="flex w-full lg:w-5/5">
              <div x-data="setup()" className="w-full">
                {/* <ul
                 className="flex w-full lg:flex-row flex-col items-start justify-between border-b-gray-100 border-b-2 text-lg font-bold"
                >
                  <template x-for="(tab, index) in tabs" :key="index">
                    <li
                     className="cursor-pointer py-2 px-4 text-gray-500"
                      :class="activeTab===index ? 'text-red-900 border-red-900 border-b-2' : ''"
                      @click="activeTab = index"
                      x-text="tab"
                    ></li>
                  </template>
                </ul> */}

                <div className="w-full bg-white p-4 mx-auto">
                  <div className="w-full grid grid-cols-5 mb-8 border-b-2 border-gray-300 shadow-md">
                    <div
                      className="cursor-pointer hover:text-red-900 "
                      onClick={() => setActiveTab(0)}
                    >
                      <h4
                        className={`text-lg font-bold text-center  ${
                          activeTab === 0 ? "border-b-2 border-red-900" : ""
                        }`}
                      >
                        İletişim Formu
                      </h4>
                    </div>
                    <div
                      className="cursor-pointer hover:text-red-900 "
                      onClick={() => setActiveTab(1)}
                    >
                      <h4
                        className={`text-lg font-bold text-center ${
                          activeTab === 1 ? "border-b-2 border-red-900" : ""
                        }`}
                      >
                        Öneri Formu
                      </h4>
                    </div>
                    <div
                      className="cursor-pointer hover:text-red-900 "
                      onClick={() => setActiveTab(2)}
                    >
                      <h4
                        className={`text-lg font-bold text-center ${
                          activeTab === 2 ? "border-b-2 border-red-900" : ""
                        }`}
                      >
                        Satış Sonrası Formu
                      </h4>
                    </div>
                    <div
                      className="cursor-pointer hover:text-red-900 "
                      onClick={() => setActiveTab(3)}
                    >
                      <h4
                        className={`text-lg font-bold text-center ${
                          activeTab === 3 ? "border-b-2 border-red-900" : ""
                        }`}
                      >
                        Test Sürüşü Formu
                      </h4>
                    </div>
                    <div
                      className="cursor-pointer hover:text-red-900 "
                      onClick={() => setActiveTab(4)}
                    >
                      <h4
                        className={`text-lg font-bold text-center ${
                          activeTab === 4 ? "border-b-2 border-red-900" : ""
                        }`}
                      >
                        İkame Araç Formu
                      </h4>
                    </div>
                  </div>
                  {activeTab === 0 && (
                    <ContactForm
                      showModal={showModal}
                      setShowModal={setShowModal}
                    />
                  )}
                  {activeTab === 1 && (
                    <SuggestionForm
                      showModal={showModal}
                      setShowModal={setShowModal}
                    />
                  )}
                  {activeTab === 2 && (
                    <AfterSaleForm
                      showModal={showModal}
                      setShowModal={setShowModal}
                    />
                  )}
                  {activeTab === 3 && (
                    <TestDriveForm
                      showModal={showModal}
                      setShowModal={setShowModal}
                    />
                  )}
                  {activeTab === 4 && (
                    <WehicleReplacementForm
                      showModal={showModal}
                      setShowModal={setShowModal}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {showModal && (
        <div
          id="kvkkModal"
          className="fixed  z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4"
        >
          <div className="relative top-40 mx-auto shadow-xl rounded-md bg-white w-[60vw]">
            <div className="flex justify-end p-2">
              <button
                onClick={() => setShowModal(false)}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>

            <div className="p-6 pt-0">
              <div className="modal-content">
                <h2 style={{ textAlign: "center" }}>KVKK Politikamız</h2>
                <ol>
                  <li>
                    Şirket’in idaresi, işin yürütülmesi, Şirket politikalarının
                    uygulanması amacıyla, özellikle; Şirket verilerinin düzgün
                    bir şekilde saklanmasını sağlamak amacıyla ve saklama
                    süreleri boyunca depolanabilmesi adına hizmet aldığımız
                    arşiv firmalarına,
                  </li>
                  <li>
                    İş süreçlerimizi hukuka ve diğer meşru menfaatlerimize uygun
                    yürütebilmemiz, olası bir yargı sürecinde savunma hakkımızı
                    kullanabilmemiz amacıyla; avukatlara, denetçiler ile
                    danışmanlık şirketlerine,
                  </li>
                  <li>
                    Hukuki yükümlülüklerimizi yerine getirebilmemiz amacıyla;
                    hukuka ve usule uygun olması koşuluyla düzenleyici ve
                    denetleyici kurumlar ile mahkeme ve icra müdürlükleri gibi
                    ilgili resmi kurumlara ve kişisel verilerinizi talep etmeye
                    yetkili olan diğer kamu kurum veya kuruluşlarına,
                  </li>
                  <li>
                    Vekil ve temsilcilerinize ilettiğiniz taleplerinizin yerine
                    getirilmesi adına; tarafınızca yetki verilmiş vekil ve
                    temsilcilerinize,
                  </li>
                  <li>
                    Kullandığımız yazılım, bulut sisteminin, e-posta
                    altyapısının ve programların sunucu veya veri tabanlarının
                    yurtdışında olması dolayısıyla yurtdışına,
                  </li>
                </ol>
                <p>
                  KVKK’nın 8. ve 9. maddelerinde belirtilen kişisel veri işleme
                  şartları ve amaçları dahilinde aktarılacaktır.
                </p>
                <p>
                  Kişisel verilerinizle ilgili olarak KVKK 11’inci maddesi
                  uyarınca;
                </p>
                <ol>
                  <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
                  <li>
                    Kişisel verileriniz işlenmişse buna ilişkin bilgi talep
                    etme,
                  </li>
                  <li>
                    Kişisel verilerinizin işlenme amacını ve bunların amacına
                    uygun kullanılıp kullanılmadığını öğrenme,
                  </li>
                  <li>
                    Kişisel verilerinizin yurt içinde veya yurt dışında
                    aktarıldığı üçüncü kişileri bilme,
                  </li>
                  <li>
                    Kişisel verilerinizin eksik veya yanlış işlenmiş olması
                    hâlinde bunların düzeltilmesini isteme,
                  </li>
                  <li>
                    KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde
                    kişisel verilerin silinmesini veya yok edilmesini isteme,
                  </li>
                  <li>
                    (d) ve (e) bentleri uyarınca yapılan işlemlerin kişisel
                    verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                  </li>
                  <li>
                    İşlenen verilerinizin münhasıran otomatik sistemler
                    vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun
                    ortaya çıkmasına itiraz etme,
                  </li>
                  <li>
                    Kişisel verilerinizin kanuna aykırı olarak işlenmesi
                    sebebiyle zarar uğramanız halinde, zararınızın giderilmesini
                    talep etme haklarına sahipsiniz.
                  </li>
                </ol>
                <p>
                  Belirtilen haklarınıza yönelik taleplerinizi, iadeli taahhütlü
                  mektup aracılığıyla, şahsen, noter aracılığıyla yazılı olarak,
                  kayıtlı elektronik posta (KEP) adresi, güvenli elektronik
                  imza, mobil imza ya da daha önce bize bildirdiğiniz ve
                  sistemimizde kayıtlı bulunan elektronik posta adresiniz var
                  ise elektronik posta adresinizi kullanmak suretiyle bize
                  iletebilirsiniz. Talepleriniz, belirtilen yollar aracılığıyla
                  bize ulaştırılmasından itibaren en geç otuz gün içinde
                  ücretsiz olarak sonuçlandırılacaktır.
                </p>
                <p>
                  Kişisel Verileri Koruma Kurumu tarafından 10 Mart 2018 tarihli
                  ve 30356 sayılı Resmi Gazete’de yayımlanan “Veri Sorumlusuna
                  Başvuru Usul Ve Esasları Hakkında Tebliğ”de belirtildiği
                  üzere, başvurunuzda yer alan talepleriniz ücretsiz olarak
                  cevaplandırılacaktır; ancak, başvurunuza cevabın yazılı olarak
                  verilmesi halinde ilk on sayfa üzerindeki her sayfa için 1 TL
                  ve cevabın CD, flash bellek gibi bir kayıt ortamında verilmesi
                  halinde kayıt ortamının maliyetini geçmeyecek bir ücret
                  tarafınızdan talep edilebilecektir. Başvurunuzun hatamızdan
                  kaynaklanması halinde ücret size iade edilecektir.
                </p>
                <p>
                  Başvurunuz KVKK’da öngörülen ilgili hükümler doğrultusunda
                  cevaplandırılacak olup başvurucunun doğru kişi olduğunun teyit
                  edilmesi amacıyla Şirket sizden bazı doğrulayıcı bilgiler
                  talep edebilecektir; bu bilgiler yalnızca kişisel verinin
                  gerçek sahibinin kim olduğunun tespit edilmesi ve başvuru
                  sonuçlarının doğru kişiyle paylaşılması amacıyla talep
                  edilecektir.
                </p>
                <p>
                  İletişim: <br />
                  ATMO Otomotiv Limited Şirketi <br />
                  Mersis No: 0103141897200001 <br />
                  KEP Adresi: <br />
                  İletişim: atmo@atmo-otomotiv.com <br />
                  Adres: Ahmet İleri Cad. No: 12, 34768, Ümraniye İstanbul
                </p>
                <button
                  onClick={() => setShowModal(false)}
                  className="mt-4 w-20 bg-red-900 p-2 text-white rounded-md"
                >
                  Kapat
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default App;
